@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-slate-100;
}

@layer base {
  :root {
    /* --color-card: #e5f9db;
    --color-logo: #40513b;
    --color-body: #83764f;
    --color-navbar: #a2a378;
    --color-text-navbar: #000;
    --color-text-base: #fff; */
    --color-card: #edf1d6;
    --color-logo: #40513b;
    --color-body: #40513b;
    --color-navbar: #9dc08b;
    --color-text-navbar: #000;
    --color-text-base: #fff;
  }
}
